import logger from './Logger'

const Segment = function({publicKey, organizationUrl, retargetingSegmentsUrl, url, handleError}) {
  const ll_$ = require('jquery');
  if (typeof jQuery !== 'undefined') ll_$.noConflict(true);

  function addOrganizationSegment(){
    logger.log('--- [SEGMENT] in addOrganizationSegment');
    ll_$.get(organizationUrl, {public_key: publicKey}).
    done((response) => {
      logger.log('--- [SEGMENT] addSegments request: Success ---', response);
      if(response.beeswax_segment && response.beeswax_segment.bw_tag){
        logger.log('--- [SEGMENT] adding bw tag ---', response.beeswax_segment.bw_tag);
        ll_$("body").append(response.beeswax_segment.bw_tag);
      }else{
        logger.log('--- [SEGMENT] there is no bw tag to add ---');
      }
    }).
    fail((error) => {
      logger.error('--- [SEGMENT] addSegments request: Error ---', error.status, error.statusText);
      handleError(new Error('Loop API Error!'), {public_key: publicKey});
    });
  }

  function addRetargetingSegments() {
    return ll_$.post(retargetingSegmentsUrl, {public_key: publicKey, url}).
      done((response) => {
        logger.log('--- [SEGMENT] addRetargetingSegments request: Success ---', response);

        const tags = response.beeswax_segment_tags;

        if (tags && tags.length > 0) {
          logger.log('--- [SEGMENT] adding bw segment tags ---', tags);
          tags.forEach((tag) => ll_$('body').append(tag));
        } else {
          logger.log('--- [SEGMENT] there are no bw segment tags to add ---');
        }
      }).
      fail((error) => {
        logger.error('--- [SEGMENT] addSegments request: Error ---', error.status, error.statusText);
        handleError(new Error('Loop API Error!'), {public_key: publicKey});
      });
  }

  return {
    addOrganizationSegment,
    addRetargetingSegments,
  }
};

export default Segment;