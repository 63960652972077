export default (function () {
  let enabled = process.env.NODE_ENV !== 'production';

  const log = (...args) => {
    if (enabled) console.log(...args);
  };

  const error = (...args) => {
    if (enabled) console.error(...args)
  };

  return {
    toggle: (x) => enabled = x,
    log,
    error,
  }
})();