import bugsnag from 'bugsnag-js'
import Segment from './lib/Segment'
import logger from './lib/Logger'

(function() {
  window.toggle_ll_logging = logger.toggle;

  var ll_DEV = process.env.NODE_ENV !== 'production';

  const bugsnagClient = bugsnag(
    {
      apiKey: BUGSNAG_JAVASCRIPT_KEY,
      autoNotify: false
    }
  );

  try {
    const CUSTOM_FIELDS = {
      wpe_text_1: 'll-custom-1',
      wpe_text_2: 'll-custom-2',
      wpe_text_3: 'll-custom-3',
      wpe_text_4: 'll-custom-4',
      wpe_text_5: 'll-custom-5',
    };

    const API_URL = `${LINK_PREFIX}://${API_SERVER_URL}/api/v1/public/page_views`;
    const DAP_API_URL = `${LINK_PREFIX}://${INTEGRATE_API_URL}/api/v1/display/pixels`;
    const ORGANIZATION_URL = `${LINK_PREFIX}://${API_SERVER_URL}/api/v1/public/organizations/me`;
    const RETARGETING_URL = `${LINK_PREFIX}://${API_SERVER_URL}/api/v1/public/retargeting_segments`;
    const WAITING_FOR_ANSWER_TIME = 100;
    const API_CONVERSION_QUERY_PARAMETER = 'll_api_conversion';
    const BOT_DETECTED_QUERY_PARAMETERS = ['ad-exchange-QA', 'x-beeswax-robot-detected'];
    let timeout;
    const href = window.document.location.href;
    const fetchDefaults = {
      credentials: 'include'
    }

    var publicKey;

    window.addEventListener('message', receiveMessage, false);
    checkIfInFrame();

    function checkIfInFrame() {
      window.parent.postMessage('ll-is-frame-q', '*');
      window.clearTimeout(timeout);
      timeout = window.setTimeout(() => {
        logger.log("--- [WPE] init ---");
        init();
      }, WAITING_FOR_ANSWER_TIME);
    }

    function init() {
      logger.log('--- [WPE] make request ---');

      const {currentScript, currentScriptisPresent} = checkForCurrentScript();

      if (!currentScriptisPresent) {
        return;
      }

      publicKey = currentScript.getAttribute('ll-public-key');
      initLlConversion();

      logger.log('--- [WPE] waiting 5 sec to exclude bots ---');

      window.setTimeout(() => {
        logger.log('--- [WPE] sending request to create page view ---');

        if (!isBotDetected()) {
          createPageView(publicKey, href);
        }

      }, PAGE_VIEW_INITIAL_DURATION);

      const segmentClient = Segment({
        publicKey,
        organizationUrl: ORGANIZATION_URL,
        retargetingSegmentsUrl: RETARGETING_URL,
        url: href,
        handleError: (...args) => bugsnagClient.notify(...args),
      });

      segmentClient.addOrganizationSegment();
      segmentClient.addRetargetingSegments();
    }

    function checkForCurrentScript() {
      const currentScript = document.currentScript || document.querySelectorAll('script[ll-public-key]')[0];
      if (!currentScript) {
        logger.error('--- [WPE Error] cannot find current stript ---');
        return {currentScript : currentScript, currentScriptisPresent : false };
      } else {
        logger.log('--- [WPE] current script found ---');
        return {currentScript : currentScript, currentScriptisPresent : true };
      }
    }

    function isBotDetected() {
      let currentUrl = new URL(window.location.href);

      return BOT_DETECTED_QUERY_PARAMETERS.some(botDetectedQueryParameter => {
        if (currentUrl.searchParams.has(botDetectedQueryParameter)) {
          return true;
        }
      });
    }

    async function createPageView(publicKey, url) {
      try {
        const response = await fetch(API_URL, {
          ...fetchDefaults,
          method: 'POST',
          body: JSON.stringify({public_key: publicKey, url}),
          headers: {
            'Content-Type': 'application/json'
          }
        })

        if (!response.ok) {
          logger.log('--- [WPE] make request: Error ---', response.status, response.statusText);

          const e = new Error(`Loop API ${response.status} Error: POST request to api/v1/public/page_views`);

          bugsnagClient.notify(e, {
            beforeSend: (report) => report.groupingHash = e.message,
            user: {
              url,
              publicKey,
            },
            metaData: {
              response: {...response},
            },
          });

          return e;
        }

        const json = await response.json();
        logger.log('--- [WPE] make request: Success ---');
        logger.log(json);
        if(json['has_active_campaigns']){
          personalize(json['custom_fields'] || {});
          setupDurationTimeInterval(publicKey, json['duration_request_id']);
        }
        if(json['is_migrated']){
          getThirdPartyPixel(publicKey)
        }
        
      } catch (error) {
        logger.log('--- [WPE] make request: Error ---', error.message);
        const e = new Error(`Loop API fetch Error: POST request to api/v1/public/page_views`);

        bugsnagClient.notify(e, {
          beforeSend: (report) => report.groupingHash = e.message,
          user: {
            url,
            publicKey,
          },
          metaData: {
            error: {...error},
          },
        });
      }
    }

    function personalize(params = {}) {
      Object.keys(CUSTOM_FIELDS).forEach((field) => {
        if (params[field]) {
          document.querySelectorAll(`.${CUSTOM_FIELDS[field]}`).forEach(el => {
            el.innerHTML = encodeURIComponent(params[field]);
          })
        }
      });
    }

    function setupDurationTimeInterval(publicKey, durationRequestId) {
      if (!durationRequestId) return null;

      const incrementTime = parseInt(TIME_ON_PUBLISHER_INCREMENT);
      const maxTime = parseInt(MAX_TIME_ON_PUBLISHER);
      let currentTime = 0;

      const interval = setInterval(async () => {
        if (currentTime >= maxTime) {
          return clearInterval(interval);
        } else {
          currentTime += incrementTime;
        }

        try {
          const response = await fetch(`${API_URL}/${durationRequestId}/increment_duration`, {
            ...fetchDefaults,
            method: 'PUT',
            body: JSON.stringify({public_key: publicKey}),
            headers: {
              'Content-Type': 'application/json'
            }
          })

          if (response.ok) {
            logger.log('Page view duration has been incremented');
          } else {
            logger.error('Unable to increment page view duration: ', response.statusText);
            clearInterval(interval);

            const e = new Error(`Loop API ${response.status} Error: PUT request to public/../increment_duration`);

            bugsnagClient.notify(e, {
              beforeSend: (report) => report.groupingHash = e.message,
              user: {
                publicKey,
                durationRequestId,
                HTTP_STATUS: response.status,
                HTTP_STATUS_TEXT: response.statusText,
              },
            });
          }
        } catch(error) {
          logger.error('Unable to increment page view duration: ', error.message);
          clearInterval(interval);

          const e = new Error(`Loop API fetch Error: PUT request to public/../increment_duration`);

          bugsnagClient.notify(e, {
            beforeSend: (report) => report.groupingHash = e.message,
            user: {
              publicKey,
              durationRequestId,
              error: {...error},
            },
          });
        }
      }, incrementTime);
    }

    function detect() {
      return Object.keys(CUSTOM_FIELDS).reduce((result, field) => {
        result[field] = !!document.querySelectorAll(`.${CUSTOM_FIELDS[field]}`).length;
        return result;
      }, {});
    }

    function receiveMessage(event) {
      if (event.data === 'll-is-frame-a') {
        window.clearTimeout(timeout);

        logger.log('--- [WPE] received `ll-is-frame-a` message ---');
        logger.log('--- [WPE] skipping init ---');
      }

      if (event.data === 'll-detect') {
        logger.log('--- [WPE] received `ll-detect` message ---');

        const fields = JSON.stringify(detect());
        event.source.postMessage('ll-detected' + fields, '*');
        return;
      }

      if (event.data.includes && event.data.includes('ll-personalize')) {
        logger.log('--- [WPE] received `ll-personalize` message ---')

        const data = event.data.replace('ll-personalize', '');
        personalize(JSON.parse(data));
        return;
      }
    }

    function initLlConversion() {
      const fireConversionEvent = async (userInputedValue) => {
        let conversionUrl = new URL(window.location.href);
        conversionUrl.searchParams.append(API_CONVERSION_QUERY_PARAMETER, userInputedValue);

        try {
          const response = await fetch(API_URL, {
            ...fetchDefaults,
            method: 'POST',
            body: JSON.stringify({public_key: publicKey, url: conversionUrl.toString()}),
            headers: {
              'Content-Type': 'application/json'
            }
          })

          if (!response.ok) {
            logger.log(`--- [CONVERSION] conversion ${userInputedValue} page view creating failed`, response.statusText);
            return;
          }

          logger.log(`--- [CONVERSION] conversion ${userInputedValue} page view created.`)
        } catch(error) {
          logger.log(`--- [CONVERSION] conversion ${userInputedValue} page view creating failed`, error.message);
        }
      };

      window.ll_conversion = fireConversionEvent;
    }

    async function getThirdPartyPixel(publicKey) {
      if (!publicKey) {
        return;
      }
 
      try {
        let url = new URL(DAP_API_URL);
        let params = {
          publicKey: publicKey,
        };
        url.search = new URLSearchParams(params).toString();
        const response = await fetch(url, {
          method: "GET"
        });
        if (!response.ok) {
          throw new Error(
            `Third party pixel API ${response.status} Error: GET request to ${DAP_API_URL}`,
          );
        }

        const json = await response.json();
        const pixels = json.response;
        pixels.forEach((pixel) => {
          addThirdPartyPixel(pixel);
        });
      } catch (error) {
        logger.log(`--- [CONVERSION] Get third party conversion Pixel failed ---`, error.message);
      }
    }

    function addThirdPartyPixel(pixel) {
      let endcodedID = `thirdPartyPixelTag${encodeURIComponent(pixel)}`;
      if (!document.getElementById(endcodedID)) {
        let thirdPartyPixel = document.createElement("script");
        thirdPartyPixel.setAttribute(
          "src",
          `https://secure.adnxs.com/px?id=${pixel}&t=1`,
        );
        thirdPartyPixel.setAttribute("type", "text/javascript");
        thirdPartyPixel.setAttribute("id", endcodedID);
        thirdPartyPixel.setAttribute("async", true);
        document.head.appendChild(thirdPartyPixel);
      }
    }

  } catch (e) {
    bugsnagClient.notify(e);
  }
})();
